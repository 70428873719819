import React from "react";
import { Container } from "react-bootstrap";

function News8() {
  return (
    <Container>
      <br />
      <p style={{ color: "grey", fontSize: "1.5rem", margin: "2rem" }}>
        December 20, 2024
      </p>
      <h2
        style={{ color: "#003561", marginBottom: "1rem", marginLeft: "2rem" }}
      >
        U.S. DOE awards Stratus Materials $2.99 Million for Next-Generation
        Cathode Development{" "}
      </h2>

      <p style={{ color: "black", fontSize: "1.5rem", margin: "2rem" }}>
        On December 20, 2024, The U.S. Department of Energy (DOE) announced
        funding for 19 projects that will advance research and development (R&D)
        critical to unlock innovative and efficient mobility options that are
        affordable for all Americans. As part of this package, Stratus Materials
        was awarded $2.99 Million for next-generation cathode development.
        Details can be found via the following link:{" "}
        <a
          href="https://www.energy.gov/eere/vehicles/funding-selections-fiscal-year-2024-vehicle-technologies-office-research-development?utm_medium=email&utm_source=govdelivery"
          target="_blank"
          rel="noopener noreferrer"
        >
          Funding Selections: Fiscal Year 2024 Vehicle Technologies Office
          Research & Development Funding Program
        </a>{" "}
      </p>

      <a
        style={{
          fontSize: "1.5rem",
          margin: "2rem",
          textDecoration: "None",
          display: "flex",
          justifyContent: "center",
        }}
        href="/news"
      >
        <strong style={{ textAlign: "center" }}>BACK TO NEWS</strong>
      </a>
      <br />
      <br />
    </Container>
  );
}

export default News8;
